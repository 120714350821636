<template>
  <div style="display:flex; justify-content: center; align-items: center; flex-direction: column; height:100%;">
    <span style="font-size:40pt; font-weight:bold">404</span>
    <img :src="require('../assets/404.png')" style="width:200px"/>
    <span style="margin-top:30px;">5초 후에 홈으로 이동합니다</span>
  </div>
</template>

<script>

export default {
  name: '404',

  mounted(){
    const self = this;
    setTimeout(() => {
      self.$router.push({ path: '/' });
    }, 5000);
  }
}
</script>
